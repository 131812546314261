import React, { useState, useEffect } from 'react'

const SMTP_CONFIGS = {
	custom: {
		label: 'Custom SMTP',
		host: '',
		port: '',
		secure: true,
	},
	outlook: {
		label: 'Outlook',
		host: 'smtp.office365.com',
		port: '587',
		secure: false,
	},
	yahoo: {
		label: 'Yahoo Mail',
		host: 'smtp.mail.yahoo.com',
		port: '465',
		secure: true,
	},
	godaddy: {
		label: 'GoDaddy',
		host: 'smtpout.secureserver.net',
		port: '465',
		secure: true,
	},
}

const ConnectEmailAccountModal = ({ isOpen, onClose, onAuthorize }) => {
	const [showSmtpForm, setShowSmtpForm] = useState(false)
	const [selectedProvider, setSelectedProvider] = useState('custom')
	const [smtpConfig, setSmtpConfig] = useState({
		email: '',
		host: '',
		port: '',
		username: '',
		password: '',
		secure: true,
		provider: 'custom',
	})

	useEffect(() => {
		if (selectedProvider) {
			const config = SMTP_CONFIGS[selectedProvider]
			setSmtpConfig(prev => ({
				...prev,
				host: config.host,
				port: config.port,
				secure: config.secure,
				provider: selectedProvider,
			}))
		}
	}, [selectedProvider])

	const handleClose = () => {
		setShowSmtpForm(false)
		setSelectedProvider('custom')
		setSmtpConfig({
			email: '',
			host: '',
			port: '',
			username: '',
			password: '',
			secure: true,
			provider: 'custom',
		})
		onClose()
	}

	if (!isOpen) return null

	const handleSmtpSubmit = e => {
		e.preventDefault()
		onAuthorize('smtp', smtpConfig)
		setSmtpConfig({
			email: '',
			host: '',
			port: '',
			username: '',
			password: '',
			secure: true,
			provider: 'custom',
		})
		setSelectedProvider('custom')
		setShowSmtpForm(false)
	}

	return (
		<div className="relative z-10">
			<div className="fixed inset-0 bg-black/75 transition-opacity" />

			<div className="fixed inset-0 z-10 w-screen overflow-y-auto">
				<div className="flex min-h-full items-center justify-center p-4 text-center">
					<div
						className={`relative transform overflow-hidden rounded-lg bg-[#002b44] text-left shadow-xl transition-all sm:my-8 ${
							showSmtpForm ? 'sm:max-w-5xl' : 'sm:max-w-xl'
						} w-full`}
					>
						<button
							onClick={handleClose}
							className="absolute right-4 top-4 text-gray-400 hover:text-gray-300 z-10"
						>
							<svg
								className="w-6 h-6"
								fill="none"
								stroke="currentColor"
								viewBox="0 0 24 24"
							>
								<path
									strokeLinecap="round"
									strokeLinejoin="round"
									strokeWidth={2}
									d="M6 18L18 6M6 6l12 12"
								/>
							</svg>
						</button>

						<div className="px-8 pt-6 pb-6 sm:p-8">
							<div className="sm:flex sm:items-start">
								<div className="mt-3 text-left sm:mt-0 w-full">
									<h3 className="text-2xl font-semibold text-white mb-6">
										Connect Email Account
									</h3>

									{!showSmtpForm ? (
										<div className="mt-4">
											<p className="text-gray-300 mb-8">
												Choose how you want to connect your email account:
											</p>
											<div className="flex flex-col space-y-4 px-4">
												<button
													onClick={() => onAuthorize('gmail')}
													className="inline-flex w-full items-center justify-center rounded-md bg-blue-600 px-4 py-4 text-base font-semibold text-white shadow-sm hover:bg-blue-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-blue-600"
												>
													<svg
														className="w-6 h-6 mr-2"
														viewBox="0 0 24 24"
														fill="currentColor"
													>
														<path d="M20.283 10.356h-8.327v3.451h4.792c-.446 2.193-2.313 3.453-4.792 3.453a5.27 5.27 0 0 1-5.279-5.28 5.27 5.27 0 0 1 5.279-5.279c1.259 0 2.397.447 3.29 1.178l2.6-2.599c-1.584-1.381-3.615-2.233-5.89-2.233a8.908 8.908 0 0 0-8.934 8.934 8.907 8.907 0 0 0 8.934 8.934c4.467 0 8.529-3.249 8.529-8.934 0-.528-.081-1.097-.202-1.625z" />
													</svg>
													Connect with Gmail
												</button>
												{/* <button
													onClick={() => onAuthorize('outlook')}
													className="inline-flex w-full items-center justify-center rounded-md bg-[#0078d4] px-4 py-4 text-base font-semibold text-white shadow-sm hover:bg-[#106ebe] focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-[#0078d4]"
												>
													<svg
														className="w-6 h-6 mr-2"
														viewBox="0 0 24 24"
														fill="currentColor"
													>
														<path d="M21.18 3H2.82C1.81 3 1 3.81 1 4.82v14.36C1 20.19 1.81 21 2.82 21h18.36c1.01 0 1.82-.81 1.82-1.82V4.82C23 3.81 22.19 3 21.18 3zM7 15.5l-4-2.5V8l4 2.5V15.5zm12-2.5l-4 2.5v-5L19 8V13z" />
													</svg>
													Connect with Outlook
												</button> */}
												{/* <button
													onClick={() => setShowSmtpForm(true)}
													className="inline-flex w-full justify-center rounded-md bg-green-600 px-4 py-4 text-base font-semibold text-white shadow-sm hover:bg-green-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-green-600"
												>
													Set Up Custom Email (SMTP)
												</button> */}
											</div>
										</div>
									) : (
										<div className="flex gap-8">
											{/* Left side - Provider list */}
											<div className="w-1/3 bg-[#001f33] rounded-lg p-4">
												<h3 className="text-lg font-semibold mb-4">
													Email Providers
												</h3>
												<div className="space-y-2">
													{Object.entries(SMTP_CONFIGS).map(([key, config]) => (
														<button
															key={key}
															onClick={() => setSelectedProvider(key)}
															className={`w-full text-left px-4 py-3 rounded transition-colors ${
																selectedProvider === key
																	? 'bg-blue-600 text-white'
																	: 'bg-[#003a5c] hover:bg-[#004a6e] text-gray-200'
															}`}
														>
															{config.label}
														</button>
													))}
												</div>
											</div>

											{/* Right side - SMTP Form */}
											<div className="w-2/3">
												<form onSubmit={handleSmtpSubmit} className="space-y-4">
													<div>
														<label className="block text-sm font-medium mb-1 text-gray-200">
															Email Address
														</label>
														<input
															type="email"
															value={smtpConfig.email}
															onChange={e =>
																setSmtpConfig({
																	...smtpConfig,
																	email: e.target.value,
																})
															}
															className="w-full p-2 rounded bg-[#003a5c] border border-[#004a6e] focus:outline-none focus:border-blue-500 text-white"
															required
														/>
													</div>
													<div>
														<label className="block text-sm font-medium mb-1 text-gray-200">
															SMTP Host
														</label>
														<input
															type="text"
															value={smtpConfig.host}
															onChange={e =>
																setSmtpConfig({
																	...smtpConfig,
																	host: e.target.value,
																})
															}
															className="w-full p-2 rounded bg-[#003a5c] border border-[#004a6e] focus:outline-none focus:border-blue-500 text-white"
															required
															readOnly={selectedProvider !== 'custom'}
														/>
													</div>
													<div>
														<label className="block text-sm font-medium mb-1 text-gray-200">
															SMTP Port
														</label>
														<input
															type="text"
															value={smtpConfig.port}
															onChange={e =>
																setSmtpConfig({
																	...smtpConfig,
																	port: e.target.value,
																})
															}
															className="w-full p-2 rounded bg-[#003a5c] border border-[#004a6e] focus:outline-none focus:border-blue-500 text-white"
															required
															readOnly={selectedProvider !== 'custom'}
														/>
													</div>
													<div>
														<label className="block text-sm font-medium mb-1 text-gray-200">
															Username
														</label>
														<input
															type="text"
															value={smtpConfig.username}
															onChange={e =>
																setSmtpConfig({
																	...smtpConfig,
																	username: e.target.value,
																})
															}
															className="w-full p-2 rounded bg-[#003a5c] border border-[#004a6e] focus:outline-none focus:border-blue-500 text-white"
															required
														/>
													</div>
													<div>
														<label className="block text-sm font-medium mb-1 text-gray-200">
															Password
														</label>
														<input
															type="password"
															value={smtpConfig.password}
															onChange={e =>
																setSmtpConfig({
																	...smtpConfig,
																	password: e.target.value,
																})
															}
															className="w-full p-2 rounded bg-[#003a5c] border border-[#004a6e] focus:outline-none focus:border-blue-500 text-white"
															required
														/>
													</div>

													<div className="flex items-center space-x-2">
														<input
															type="checkbox"
															checked={smtpConfig.secure}
															onChange={e =>
																setSmtpConfig({
																	...smtpConfig,
																	secure: e.target.checked,
																})
															}
															className="rounded"
															disabled={selectedProvider !== 'custom'}
														/>
														<label className="text-sm text-gray-200">
															Use SSL/TLS
														</label>
													</div>
												</form>
											</div>
										</div>
									)}
								</div>
							</div>
						</div>

						<div className="px-8 py-4 sm:flex sm:flex-row-reverse sm:px-8">
							{showSmtpForm ? (
								<>
									<button
										type="button"
										onClick={handleSmtpSubmit}
										className="inline-flex w-full justify-center rounded-md bg-green-600 px-4 py-2.5 text-sm font-semibold text-white shadow-sm hover:bg-green-500 sm:ml-3 sm:w-auto"
									>
										Connect
									</button>
									<button
										type="button"
										onClick={() => setShowSmtpForm(false)}
										className="mt-3 inline-flex w-full justify-center rounded-md bg-[#003a5c] px-4 py-2.5 text-sm font-semibold text-white shadow-sm hover:bg-[#004a6e] sm:mt-0 sm:w-auto"
									>
										Back
									</button>
								</>
							) : (
								<button
									type="button"
									onClick={handleClose}
									className="mt-3 inline-flex w-full justify-center rounded-md bg-[#003a5c] px-4 py-2.5 text-sm font-semibold text-white shadow-sm hover:bg-[#004a6e] sm:mt-0 sm:w-auto"
								>
									Close
								</button>
							)}
						</div>
					</div>
				</div>
			</div>
		</div>
	)
}

export default ConnectEmailAccountModal
