import React from 'react'
import NavBar from '../LandingPage/NavBar'

function Terms() {
	const baseStyle = 'max-w-4xl mx-auto p-5 text-gray-700 bg-white'

	return (
		<>
			<NavBar isNotLandingPage={true} />
			<div className={baseStyle}>
				<div className="max-w-4xl mx-auto p-5">
					<div className="container">
						<h2 className="text-3xl font-bold text-gray-900 mb-4">
							Terms of Service for LoadLine.AI | Leapcore Pte. Ltd.
						</h2>

						<p className="text-md mb-2 text-gray-600">
							Last updated: August 10, 2024
						</p>

						<section className="mb-6">
							<p className="text-gray-700 mb-3">
								Please read these Terms of Service carefully before using
								LoadLine.AI operated by Fierce Trade LLC.
							</p>
						</section>

						<section className="mb-6">
							<h3 className="text-xl font-semibold text-gray-800 mb-3">
								1. Acceptance of Terms
							</h3>
							<p className="text-gray-700 mb-3">
								By accessing or using our service, you agree to be bound by
								these Terms. If you disagree with any part of the terms, then
								you may not access our service.
							</p>
						</section>

						<section className="mb-6">
							<h3 className="text-xl font-semibold text-gray-800 mb-3">
								2. Email Integration Services
							</h3>
							<p className="text-gray-700 mb-3">
								Our service provides email integration with various providers,
								including Microsoft Outlook and Gmail. By using these
								integrations:
							</p>
							<ul className="list-disc pl-5 mb-3">
								<li className="text-gray-700">
									You authorize LoadLine.AI to access and send emails on your
									behalf
								</li>
								<li className="text-gray-700">
									You acknowledge that we will store necessary authentication
									tokens to provide this service
								</li>
								<li className="text-gray-700">
									For Microsoft services, we comply with Microsoft's API Terms
									of Use and data protection standards
								</li>
								<li className="text-gray-700">
									For Gmail services, we comply with Google's API Services User
									Data Policy
								</li>
							</ul>
						</section>

						<section className="mb-6">
							<h3 className="text-xl font-semibold text-gray-800 mb-3">
								3. Microsoft Services Integration
							</h3>
							<p className="text-gray-700 mb-3">
								When using our Microsoft email integration:
							</p>
							<ul className="list-disc pl-5 mb-3">
								<li className="text-gray-700">
									We request limited permissions (Mail.Send and User.Read) to
									perform necessary functions
								</li>
								<li className="text-gray-700">
									Your Microsoft account credentials are never stored on our
									servers
								</li>
								<li className="text-gray-700">
									You can revoke access to your Microsoft account at any time
									through your Microsoft Account settings
								</li>
								<li className="text-gray-700">
									We handle your data in accordance with Microsoft's data
									protection requirements
								</li>
							</ul>
						</section>

						<section className="mb-6">
							<h3 className="text-xl font-semibold text-gray-800 mb-3">
								4. User Accounts
							</h3>
							<p className="text-gray-700 mb-3">
								When you create an account with us, you must provide accurate,
								complete, and current information. Failure to do so constitutes
								a breach of the Terms, which may result in immediate termination
								of your account.
							</p>
						</section>

						<section className="mb-6">
							<h3 className="text-xl font-semibold text-gray-800 mb-3">
								5. Service Availability
							</h3>
							<p className="text-gray-700 mb-3">
								We strive to provide uninterrupted service but do not guarantee
								that the service will be available at all times. We reserve the
								right to modify, suspend, or discontinue the service with or
								without notice.
							</p>
						</section>

						<section className="mb-6">
							<h3 className="text-xl font-semibold text-gray-800 mb-3">
								6. Intellectual Property
							</h3>
							<p className="text-gray-700 mb-3">
								The service and its original content, features, and
								functionality are owned by Fierce Trade LLC and are protected by
								international copyright, trademark, patent, trade secret, and
								other intellectual property laws.
							</p>
						</section>

						<section className="mb-6">
							<h3 className="text-xl font-semibold text-gray-800 mb-3">
								7. Limitation of Liability
							</h3>
							<p className="text-gray-700 mb-3">
								In no event shall LoadLine.AI, nor its directors, employees,
								partners, agents, suppliers, or affiliates, be liable for any
								indirect, incidental, special, consequential or punitive
								damages, including without limitation, loss of profits, data,
								use, goodwill, or other intangible losses.
							</p>
						</section>

						<section className="mb-6">
							<h3 className="text-xl font-semibold text-gray-800 mb-3">
								8. Changes to Terms
							</h3>
							<p className="text-gray-700 mb-3">
								We reserve the right to modify or replace these Terms at any
								time. If a revision is material, we will try to provide at least
								30 days' notice prior to any new terms taking effect.
							</p>
						</section>

						<section className="mb-6">
							<h3 className="text-xl font-semibold text-gray-800 mb-3">
								9. Contact Us
							</h3>
							<p className="text-gray-700 mb-3">
								If you have any questions about these Terms, please contact us:
							</p>
							<ul className="text-gray-700 mb-3">
								<li>
									<p>By email: info@loadline.ai</p>
								</li>
								<li>
									<p>
										By visiting this page on our website:{' '}
										<a
											href="https://LoadLine.ai/terms"
											rel="external nofollow noopener noreferrer"
											target="_blank"
										>
											https://loadline.ai/terms
										</a>
									</p>
								</li>
							</ul>
						</section>
					</div>
				</div>
			</div>
		</>
	)
}

export default Terms
